

































import Axios from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DetailTag extends Vue {
  @Prop({ required: false })
  label!: string;
  @Prop({ required: true })
  data!: string;
  @Prop({ default: "" })
  dataLink!: string;
  @Prop({ default: "string" })
  type!: string;
  @Prop({ default: false })
  downloadButton!: boolean;

  imageDialogVisible = false;

  forceFileDownload(response: any) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", this.data); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
  download() {
    Axios({
      method: "get",
      url: this.dataLink,
      responseType: "arraybuffer"
    })
      .then((response: any) => {
        this.forceFileDownload(response);
      })
      .catch(() => console.log("error occured"));
  }
}
